/**
 * Handle custom selects behaviour
 * @constructor
 */
class Selects {

  /**
   * Inits the module with necessary functions
   * @method
  **/
  constructor(){
    this.refresh = this.refresh.bind(this);
    this.bind = this.bind.bind(this);
    this.enable = this.enable.bind(this);
    this.link = this.link.bind(this);
    // this.create = this.create.bind(this);
    this.change = this.change.bind(this);
    this.refresh();
  }

  bind( dom ){

    dom = dom || document;

    document.body.listen( 'change', '.custom-select select', this.change );
    document.body.listen( 'change', 'select[data-enable]', this.enable );
    document.body.listen( 'change', 'select[data-link]', this.link );

  }

  link( e ){

    const selected = e.target.querySelectorAll('option')[e.target.selectedIndex],
          value = selected.dataset.link ? selected.dataset.link : e.target.value;

    if( /^#/.test(value) ){
      window.scroll({
        behavior: 'smooth',
        top: document.querySelector(value).offsetTop - ( document.querySelector('header[role="banner"]') ? document.querySelector('header[role="banner"]').offsetHeight : 0) - 10
      });
    }
    else{
      window.location.href = value;
    }

  }

  enable( e, close ){

    const trigger = e.originalEvent ? e.currentTarget : e,
          target = document.getElementById(trigger.dataset.enable);

    if( trigger.value.length > 0 && target.querySelector('[disabled]') ){
      target.classList.add('active');
      target.querySelector('.std-field.disabled').classList.remove('disabled');
      target.querySelector('[disabled]').removeAttribute('disabled');
    }
    else if( trigger.value.length === 0 || close ){
      target.classList.remove('active');
      target.querySelector('.std-field').classList.add('disabled');
      target.querySelector('select').setAttribute('disabled', true);

      if( target.querySelector('[data-enable]') ){
        this.enable( target.querySelector('[data-enable]'), true );
      }
    }

  }

  change( e ){
    const select = e.target ? e.target : e,
        label = select.parentNode.querySelector( 'label' ),
        optIndex = select.selectedIndex,
        selected = select.querySelectorAll( 'option' )[ optIndex ];

    label.textContent = selected.textContent;
    if( optIndex == 0 && selected.value.length == 0 ){
      select.classList.add('placeholder');
    }
    else{
      select.classList.remove('placeholder');
    }

    if( selected.dataset.class ){
      label.classList.add(selected.dataset.class);
      label.dataset.class = selected.dataset.class;
    }
    else if( label.dataset.class ){
      label.classList.remove(label.dataset.class);
      label.removeAttribute('data-class');
    }

    if( selected.classList.contains('disabled') ){
      label.classList.add('disabled');
    }
    else{
      label.classList.remove('disabled');
    }

  }

  refresh( dom ){
    dom = dom || document;

    if( !dom.querySelector( '.custom-select select' ) ){
      return;
    }
    // this.create( dom );

    document.qsa('.custom-select select').forEach( (select) => {
      if( select.selectedIndex === 0 && select.querySelector('option').value.length == 0 ){
        select.classList.add('placeholder');
      }
    })

    this.bind( dom );

  }
}

export default new Selects();
